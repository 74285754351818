<template>
  <a-layout class="box">
    <a-layout-sider class="slider" width="288">
      <a-row class="slider_header" type="flex">
        <a-col :flex="1" style="color: #222; font-size: 16px">任务列表</a-col>
        <a-col
          flex="118px"
          style="color: #604bdc; font-size: 14px; cursor: pointer"
        >
          <div class="btn_create" @click="createTask">创建任务</div>
          <a-dropdown
            overlayClassName="rays-tuner-simplify-tool-task-menu-type"
          >
            <a class="ant-dropdown-link" @click.prevent>
              <svg-icon class="icon icon_funnel" icon-class="funnel" />
            </a>
            <template #overlay>
              <a-menu @click="taskListMenuClick">
                <a-menu-item key="1" v-if="taskListType == '2'">
                  显示全部
                </a-menu-item>
                <a-menu-item key="2" v-if="taskListType == '1'">
                  显示已完成
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </a-col>
      </a-row>
      <div class="slider_item_box" id="shader_simplifytool_task_scroll">
        <div
          v-for="(item, index) in taskList"
          :key="index"
          class="task_item"
          :class="{ selected: item.selected }"
          @click="taskListClick"
          :data-tid="item.id"
        >
          <div class="status" :class="item.statClass">{{ item.statStr }}</div>
          <a-tooltip placement="top" :destroyTooltipOnHide="true">
            <template #title>
              <span v-if="item.optTip">{{ item.optTip }}</span>
            </template>
            <svg-icon
              class="icon btn_ope"
              style="right: 52px"
              :icon-class="item.optIcon"
              v-if="item.optTip"
            />
          </a-tooltip>
          <a-dropdown
            class="card-drop-con"
            overlayClassName="rays_tuner_simplifytool_task_menu"
          >
            <a class="ant-dropdown-link" @click.prevent.stop>
              <svg-icon class="icon more" icon-class="more" />
            </a>
            <template #overlay>
              <a-menu @click="taskListEditMenuClick">
                <a-menu-item :key="item.task_number">
                  <svg-icon icon-class="delete" class="top-icon card-del" />
                  删除
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
          <div class="time" :class="item.titleClass">
            {{ item.task_number }}
          </div>
          <div class="model">
            <div>手机型号: {{ item.params.device_type || '' }}</div>
            <div>
              帧范围: {{ item.params.begin_frame || 0 }}-{{
                item.params.end_frame || 0
              }}
            </div>
          </div>
        </div>
      </div>
    </a-layout-sider>
    <router-view></router-view>
  </a-layout>
</template>
<script>
import {
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  watch,
  nextTick,
  createVNode,
} from 'vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import router from '@/routes';
import { setShaderSimplify, setPatDeviceType } from '@/models/tunerStorage';
import store from '@/vuex/store';
import { message, Modal } from 'ant-design-vue';
import PerfectScrollbar from 'perfect-scrollbar';
import { debounce } from 'lodash';
import api from '@/api/shader-tools';

export default defineComponent({
  name: 'SimplifyTool',
  setup() {
    const $_user = store.state.user.userinfo;
    const $_email = $_user.email;
    const $_pat = store.state.shaderTool.currentPat;
    let simplifyTaskDom = null;
    let simplifyTaskPs = null;
    const taskListType = ref('1');
    const taskStatStr = [
      '队列中',
      '队列中',
      '工作中',
      '暂停中',
      '暂停',
      '中断',
      '',
      '已删除',
      '删除中',
    ];
    const taskStatClass = [
      'queued',
      'queued',
      'working',
      'pause',
      'pause',
      'stop',
      'finish',
      'tstop',
      'tstop',
    ];
    const taskTitleClass = ['', '', '', '', '', 'tstop', 'tfinish', '', ''];
    const taskOption = [3, 3, 3, 1, 1, 2, 0, 0, 0]; //'1'
    const taskOptionIcon = ['', 'play', 'redo', 'stop'];
    const taskOptionTip = ['', '开始任务', '重启任务', '暂停任务'];

    const curShader = computed(
      () => store.state.shaderTool.currentShader || {}
    );

    watch(
      () => curShader.value.shader_id,
      () => {
        // console.log('watch curShader');
        getTaskList();
      }
    );

    const taskUpdate = computed(
      () => store.state.shaderTool.shaderSimplify.taskUpdate
    );

    watch(taskUpdate, () => {
      // console.log('watch curShader', shader);
      getTaskList();
    });

    const curSimplifyTask = computed(
      () => store.state.shaderTool.currentShaderSimplifyTask || {}
    );

    const taskList = ref([]);
    // for (let i = 0; i < 20; i++) {
    //     let _stat = (i - Math.floor(i/6)*6) + 1;
    //     let _option = taskOption[_stat];
    //     taskList.value.push({
    //         "id": 1 + i,
    //         "status": _stat,
    //         statStr: taskStatStr[_stat],
    //         statClass: taskStatClass[_stat],
    //         titleClass: taskTitleClass[_stat],
    //         optIcon: taskOptionIcon[_option],
    //         optTip: taskOptionTip[_option],
    //         selected: false,
    //         "intro": "任务备注",
    //         "type_id": "1",
    //         "add_time": "2021-11-23 15:40:40",
    //         "start_time": "2021-11-23 15:40:40",
    //         "end_time": "2021-11-23 15:40:40",
    //         "user_id": "1",
    //         "number": "RW2021112301",
    //         "task_number": "RW202111230" + i,
    //         "params": {
    //             "shader_id": 1,
    //             "pat_name": "设备名称2",
    //         }
    //     });
    // }

    const createTask = () => {
      router.push({
        path: '/shaderhome/simplify/create',
      });
    };

    const getTaskList = async (times) => {
      // console.log('getTaskList', curShader.value);
      times = times || 0;
      const params = {
        type_id: 1,
        user_name: $_email,
        pat_name: $_pat.pat_name,
        shader_id: curShader.value.shader_id,
      };
      const res = await api.taskGets(params);
      if (!res) {
        message.error('获取简化任务列表失败!');
        return;
      }
      if (res.code != 0) {
        message.error('获取简化任务列表失败:', res.msg);
        return;
      }
      const _data = res.data || [];
      let _pagestat = 0;
      let _loopStat = -1;
      let _tmptaskno = curSimplifyTask.value.task_number;
      let _autoSel = true;
      let _autoNo = 0;

      setShaderSimplify(null);
      // console.log(curSimplifyTask.value, _tmptaskno);
      for (let i = 0; i < _data.length; i++) {
        const _stat = _data[i].status;
        const _option = taskOption[_stat];
        _data[i].statStr = taskStatStr[_stat];
        _data[i].statClass = taskStatClass[_stat];
        _data[i].titleClass = taskTitleClass[_stat];
        _data[i].optIcon = taskOptionIcon[_option];
        _data[i].optTip = taskOptionTip[_option];
        _data[i].selected = false;
        _data[i].params = JSON.parse(_data[i].params || '{}') || {};
        if (
          taskList.value.length == 0 &&
          _tmptaskno == _data[i].task_number &&
          _data[i].status == 6
        ) {
          _data[i].selected = true;
          _pagestat = 1;
          setShaderSimplify(_data[i]);
          setPatDeviceType(_data[i].params.device_type);
          _autoSel = false;
        } else if (_data[i].status == 6 && _autoSel) {
          _autoNo = i;
          _autoSel = false;
        }
        if (
          _data[i].status == 3 ||
          _data[i].status == 8 ||
          _data[i].status == 1
        ) {
          _loopStat = _data[i].status;
        }
      }
      if (
        !curSimplifyTask.value.task_number &&
        _data[_autoNo] &&
        _data[_autoNo].status == 6
      ) {
        // console.log(_data, _autoNo)
        _data[_autoNo].selected = true;
        _pagestat = 1;
        setShaderSimplify(_data[_autoNo]);
      }
      taskList.value = _data;
      if (times < 10 && (_loopStat == 3 || _loopStat == 8)) {
        setTimeout(() => {
          getTaskList(++times);
        }, 3000);
      }
      // else if (times < 2 && _loopStat == 1) {
      //     setTimeout(()=>{
      //         getTaskList(++times);
      //     }, 3000);
      // }
      if (simplifyTaskPs && simplifyTaskDom) {
        nextTick(() => {
          simplifyTaskPs.update();
        });
      }
      // console.log('taskList',taskList);
      store.commit('setShaderSimplifyPageStat', _pagestat); //0 空界面  1 有数据
    };

    const operateAsyncTask = debounce(
      async (task, opt) => {
        // console.log('operateAsyncTask', task_id, opt);
        if (!task || !task.task_number || !opt || opt > 3) return;
        task.optioning = true;
        let _opts = [
          '',
          'stopAsyncTask',
          'deleteAsyncTask',
          'continueAsyncTask',
        ];
        let _strs = ['', '暂停', '删除', '重启'];
        let _opt = _opts[opt];
        let _msg = _strs[opt];
        const params = {
          user: $_email,
          task_id: task.task_number,
        };
        const res = await api.operateAsyncTask(_opt, params);
        task.optioning = false;
        if (!res) return;
        if (res.code == 0) {
          getTaskList();
          message.success(_msg + '任务成功！');
        } else {
          message.error(_msg + '任务失败：' + res.detail);
        }
      },
      300,
      { leading: true, trailing: false }
    );

    const taskListClick = (event) => {
      // console.log('taskListClick', event);
      let _item = null;
      let _ope = false;
      let _path = event.target;
      while (_path) {
        if (
          (_path.nodeName == 'svg' &&
            _path.className.baseVal.includes('btn_ope')) ||
          (_path.tagName == 'use' && _path.href.baseVal != '#Icon_stop')
        ) {
          _ope = true;
        }
        if (_path.className == 'task_item') {
          _item = _path;
          break;
        }
        // if (_path == event.currentTarget) {
        //     break;
        // }
        _path = _path.parentNode;
      }
      if (_item) {
        // console.log('_item', _item, _item.dataset, _ope);
        for (let i = 0; i < taskList.value.length; i++) {
          taskList.value[i].selected = false;
          if (taskList.value[i].id == _item.dataset.tid) {
            let _simplifyTask = taskList.value[i];
            if (!_ope) {
              router.push({
                path: '/shaderhome/simplify/view',
              });
              // console.log('_simplifyTask',_simplifyTask);
              if (_simplifyTask.status == 6) {
                _simplifyTask.selected = true;
                store.commit('setShaderSimplifyPageStat', 1);
                setShaderSimplify(_simplifyTask);
              } else {
                store.commit('setShaderSimplifyPageStat', 0);
              }
            } else if (!_simplifyTask.optioning) {
              let _stat = parseInt(_simplifyTask.status);
              // console.log('opt', _simplifyTask, _stat);

              switch (_stat) {
                case 0:
                case 1:
                case 2:
                  operateAsyncTask(_simplifyTask, 1);
                  break;
                case 3:
                  message.warning('任务正在暂停，请等待。。。');
                  break;
                case 4:
                case 5:
                  operateAsyncTask(_simplifyTask, 3);
                  break;
                case 8:
                  message.warning('任务正在删除，请等待。。。');
                  break;
                default:
                  break;
              }
            }
          }
        }
      }
    };

    const taskListMenuClick = (value) => {
      // console.log('taskListMenuClick', value);
      taskListType.value = value.key;
      switch (value.key) {
        case '1': //显示全部
          getTaskList();
          break;
        case '2': //显示已完成
          for (let i = 0; i < taskList.value.length; i++) {
            const _item = taskList.value[i];
            if (_item.status != 6) {
              taskList.value.splice(i, 1);
              i--;
            }
          }
          if (simplifyTaskPs && simplifyTaskDom) {
            nextTick(() => {
              simplifyTaskPs.update();
            });
          }
          break;
        default:
          break;
      }
    };

    const delConfirm = (task) => {
      Modal.confirm({
        title: '删除简化任务',
        icon: createVNode(ExclamationCircleOutlined),
        content: '确定要删除该任务吗？（' + task.task_number + '）',
        okText: '删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise((resolve) => {
            operateAsyncTask(task, 2);
            resolve();
          });
        },
      });
    };

    const taskListEditMenuClick = (value) => {
      for (let i = 0; i < taskList.value.length; i++) {
        if (taskList.value[i].task_number == value.key) {
          let _task = taskList.value[i];
          if (_task.status == 3) {
            message.warning('任务正在暂停，请等待。。。');
          } else if (_task.status == 8) {
            message.warning('任务正在删除，请等待。。。');
          } else {
            delConfirm(_task);
          }
          break;
        }
      }
      // console.log('taskListEditMenuClick', value);
    };

    onMounted(() => {
      // console.log(process.env.NODE_ENV);
      // 初始化简化任务列表scroll
      simplifyTaskDom = document.querySelector(
        '#shader_simplifytool_task_scroll'
      );
      simplifyTaskPs = new PerfectScrollbar(simplifyTaskDom);
    });

    onBeforeUnmount(() => {
      if (simplifyTaskPs) {
        simplifyTaskPs.destroy();
        simplifyTaskPs = null;
      }
      simplifyTaskDom = null;
    });
    getTaskList();
    return {
      taskList,
      taskListType,
      taskListClick,
      taskListMenuClick,
      taskListEditMenuClick,
      createTask,
    };
  },
});
</script>

<style scoped lang="scss">
.top-icon {
  width: 16px;
  height: 16px;
}
.card-drop-con {
  position: absolute;
  bottom: 18px;
  right: 24px;
}
.card-del {
  margin-top: -5px;
  vertical-align: middle;
}
.icon {
  width: 24px;
  height: 24px;
  color: #d8d8d8;
}
.icon:focus {
  outline: none;
}

.icon_funnel {
  margin-left: 8px;
  color: #aaa;
  vertical-align: -7px;
  &:hover {
    background-color: #f0f0f0;
    color: #604bdc;
  }
}
.slider {
  background-color: #fff;
  .slider_header {
    height: 72px;
    line-height: 72px;
    padding-left: 24px;
    .btn_create {
      display: inline-block;
      width: 72px;
      text-align: center;
      line-height: 24px;
      font-size: 14px;
      border-radius: 2px;
      cursor: pointer;
      color: #604bdc;
      background-color: #fff;

      &:hover {
        color: #fff;
        background-color: #604bdc;
      }
    }
  }
  .task_item {
    position: relative;
    height: 126px;
    padding: 24px;
    cursor: pointer;
    .time {
      font-size: 14px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #8c8c8c;
    }
    .tfinish {
      color: #3c3c3c;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
    }
    .tstop {
      color: #ff2222;
    }
    .model {
      margin-top: 6px;
      font-size: 13px;
      color: #aaa;
      line-height: 24px;
    }
    .status {
      position: absolute;
      right: 24px;
      top: 20px;
      border-radius: 2px;
      font-size: 12px;
      padding: 4px;
    }
    .working {
      color: #604bdc;
      background: rgba(96, 75, 220, 0.2);
    }
    .finish {
      display: none;
    }
    .pause {
      color: #aaa;
      background: #f0f0f0;
    }
    .stop {
      color: #f5222d;
      background: rgba(245, 34, 45, 0.2);
    }
    .queued {
      color: #aaa;
      background: #f0f0f0;
    }
    &:hover {
      .time {
        color: #3c3c3c;
      }
      .model {
        color: #666;
      }
      background-color: #f9f9f9;
    }

    &.selected {
      .time {
        color: #3c3c3c;
      }
      .model {
        color: #666;
      }
      background-color: #f9f9f9;
    }
  }

  .slider_item_box {
    position: relative;
    height: calc(100vh - 130px);
  }

  .btn_ope {
    position: absolute;
    bottom: 24px;
    &:hover {
      background-color: #f0f0f0;
      color: #604bdc;
    }
  }

  .more {
    &:hover {
      background-color: #f0f0f0;
      color: #604bdc;
    }
  }
}
</style>
<style lang="scss">
.rays_tuner_simplifytool_task_menu .ant-dropdown-menu-item:hover {
  color: #ff2222;
}
</style>
